new WOW().init();

$(document).on('click', '.c-accordion__title', function () {
  if ($(this).hasClass('active')) {
    $('.c-accordion__content').slideUp();
    $('.c-accordion__title').removeClass('active');
    return false;
  }
  $('.c-accordion__title').removeClass('active');
  $(this).addClass('active');
  var contrl = $(this).next();
  $('.c-accordion__content').slideUp();
  $(contrl).slideDown();
});

$(document).on('click', '#covid19content h3', function () {
  if ($(this).hasClass('active')) {
    $('#covid19content ul').slideUp();
    $('#covid19content h3').removeClass('active');
    return false;
  }
  $('#covid19content h3').removeClass('active');
  $(this).addClass('active');
  var contrl = $(this).next();
  $('#covid19content ul').slideUp();
  $(contrl).slideDown();
});
